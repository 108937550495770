<template>
    <Avatar :name="user.name" :avatar="user.avatar" :size="size"></Avatar>
</template>

<script>
import Avatar from "../Avatar.vue";
export default {
    name: "UserAvatar",
    components: {
        Avatar,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: 'medium',
        }
    },
}
</script>

<style scoped>

</style>
