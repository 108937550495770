<template>
    <div class="modal fade text-dark" :id="id" tabindex="-1" :aria-labelledby="id + '_label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="id + '_label'">
                        <slot name="title">Are you sure?</slot>
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-start">
                    <slot name="text">Please confirm if you wish to continue.</slot>
                </div>
                <div class="modal-footer">
                <slot name="buttons">
                    <Button color="light" data-bs-dismiss="modal" ref="cancelButtonRef">
                        <slot name="cancel-button-text">Cancel</slot>
                    </Button>
                    <Button :color="confirmationButtonColor" @click="$emit('confirmed')">
                        <slot name="confirm-button-text">Continue</slot>
                    </Button>
                </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConfirmationModal",
}
</script>
<script setup>
import Button from './Button.vue'
const props = defineProps({
    id: {
        type: String,
        required: true,
        default: false,
    },
    confirmationButtonColor: {
        type: String,
        default: 'primary'
    },
})
</script>

<style scoped>

</style>
