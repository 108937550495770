<template>
    <div class="w-full mx-3">
        <TransitionGroup name="fade">
            <div v-for="(alert, index) in alerts" class="alert alert-info d-flex align-items-center">
                <div class="flex-shrink-1 fs-4 me-2">
                    <i v-if="alert.type === 'new_booking'" class="mdi mdi-car-info"></i>
                    <i v-else-if="alert.type === 'booking_timing_confirmed'" class="mdi mdi-account-check"></i>
                    <i v-else class="mdi mdi-information-variant"></i>
                </div>
                <div class="flex-grow-1">
                    <Markdown :content="alert.content"/>
                </div>
                <button
                    type="button"
                    class="btn-close flex-shrink-1"
                    @click="acknowledgeAlert(alert, index)"
                    title="Dismiss this alert"
                    aria-label="Close"></button>
            </div>
        </TransitionGroup>
    </div>
</template>

<script>
import Markdown from './Markdown.vue'
export default {
    name: "Alerts",
    components: {
        Markdown,
    },
    props: {
        fetchAlertsRoute: {
            type: String,
            required: true,
        },
        csrf: {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            alerts: [],
            stopRequesting: false,
        }
    },
    methods: {
        fetchAlerts: function () {
            this.$http.get(this.fetchAlertsRoute).then((response) => {
                if (!Array.isArray(response.data)) {
                    this.stopRequesting = true
                    return
                }
                this.alerts = response.data
            })

            if (!this.stopRequesting) {
                setTimeout(this.fetchAlerts, 60*1000)
            }
        },
        acknowledgeAlert: function (alert, alertIndex) {
            this.alerts.splice(alertIndex, 1)
            this.$http.post(this.route('alerts.update', { 'alert': alert.uuid }), {
                _token: this.csrf,
                acknowledge: 1
            })
        }
    },
    beforeMount() {
        this.fetchAlerts()
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
