<template>
    <li class="dropdown notification-list me-3">
        <a
          id="topbar-tenantdrop"
          class="nav-link dropdown-toggle nav-user arrow-none me-0 d-flex align-items-center"
          aria-expanded="false"
          aria-haspopup="true"
          data-bs-toggle="dropdown"
          href="#"
          role="button"
        >
            {{ currentTenant ? currentTenant?.name : 'Access client area' }}
        </a>
        <div
            class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu"
            aria-labelledby="topbar-tenantdrop"
        >
            <form class="p-2 mb-2 bg-body-tertiary border-bottom">
                <input
                    @input="search"
                    v-model="query"
                    class="form-control"
                    autocomplete="false"
                    placeholder="Type to search..."
                    type="search"
                >
            </form>
            <ul class="list-unstyled">
                <template v-if="!companies.length">
                    <span class="px-3">No results found</span>
                </template>
                <template v-else>
                    <li v-for="company in companies" class="w-100">
                        <a @click.prevent="accessTenantArea(company.pin)" class="dropdown-item py-2 d-flex align-items-center" href="#">
                            <span :class="company.is_active ? 'bg-success' : 'bg-danger'" class="d-inline-block rounded-circle p-1 me-2"></span>
                            {{ company.name }}
                        </a>
                    </li>
                </template>
            </ul>
        </div>
    </li>
</template>

<script setup>
import { ref, inject, onMounted } from "vue"
import { debounce } from 'lodash'

const axios = inject('axios')

const currentTenant = ref(null);
const companies = ref([])
const tenant = ref(null)
const submitting = ref(false)

const accessTenantArea = (pin) => {
    submitting.value = true
    axios.post(route('api.admin.switch-tenant'), {
        pin: pin
    }).then(response => {
        window.location.href = response.data.redirect_url;
    }).catch(error => {
        submitting.value = false
        alert(error.response.data.message)
    })
};

const query = ref('')
const search = debounce(function (providerId) {
    axios.get(route('admin.company.index'), {
        params: {
            query: query.value
        }
    }).then(response => {
        companies.value = response.data.data
    })
}, 200)
onMounted(() => {
    currentTenant.value = window?.tenant;
    axios.get(route('admin.company.index')).then(response => {

        companies.value = response.data.data
    })
})

</script>
